<template>
  <div :class="['logo-' + bookieCode, 'bookies', 'center']" />
</template>

<script>

export default {
  name: 'BookiesLogos',
  data: () => ({
  }),
  props: {
    bookieCode: String
  },
  computed: {
  },
  methods: {
  }
}
</script>

<style>
.bookies {
  display: block;
  background-color: red;
  margin: 0;
  border-radius: 100%;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  /* width: 50%; */
}

/* William Hill - First column of image file */
.logo-WH {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') no-repeat 0px -855px;
}

/* RealBookie - Second column of image file */
.logo-RB3 {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') no-repeat -45px 0px;
}

/* BetDeluxe - Second column of image file */
.logo-BD2 {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') no-repeat -45px -180px
}

/* Dave Dwyer - Second column of image file*/
.logo-DD {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') no-repeat -45px -585px;
}

/* Winbet - Second column of image file */
.logo-WB3 {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') no-repeat -45px -630px;
}

/* Paddy Power - Second column of image file */
.logo-PP2 {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') no-repeat -45px -720px;
}

/* Unibet UK - Second column of image file */
.logo-UB_U {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') no-repeat -45px -765px;
}

/* Betway - Second column of image file */
.logo-BW2 {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') no-repeat -45px -675px;
}

/* Sportsbet UK - Second column of image file  */
.logo-SBU {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') no-repeat -45px -810px;
}

/* 888 Sports - Second column of image file  */
.logo-888 {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') no-repeat -45px -855px;
}

/* Tab Touch WA - First column of image file */
.logo-OB {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') no-repeat 0 -1260px;
}

/* Topsport - First column of image file */
.logo-TS2 {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') no-repeat 0 -945px;
}

/* Sportsbet - First column of image file */
.logo-SB2 {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') no-repeat 0 -765px;
}

/* Betfair - First column of image file */
.logo-BF {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') no-repeat 0 -225px;
}

/* Ladbrokes AU - First column of image file */
.logo-LB2 {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') no-repeat 0 -495px;
}

/* Topbetta with a twisit - (THIS IS NOT BEING USED) */
.logo-TB2 {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') no-repeat 0 315px;
}

/* Official Price - First column of image file */
.logo-OP {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') no-repeat 0 -585px;
}

/* BetVictor - First column of image file */
.logo-BV {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') no-repeat 0 -270px;
}

/* SkyBet - First column of image file  */
.logo-SB6 {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') no-repeat 0 -720px;
}

/* Coral - First column of image file  */
.logo-CB3 {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') no-repeat 0 -405px;
}

/* Boyle Sports - First column of image file */
.logo-BS3 {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') no-repeat 0 -360px;
}

/* RaceBets - First column of image file */
.logo-RB2 {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') no-repeat 0 -675px;
}

/* BlueBet - First column of image file */
.logo-BB3 {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') no-repeat 0 -315px;
}

/* Unibet - First column of image file */
.logo-UB {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') no-repeat 0 -900px;
}

/* Ladbrokes UK - First column of image file */
.logo-LB2U {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') no-repeat 0 -630px;
}

/* Playup - Second column of image file */
.logo-CB2 {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') no-repeat -45px -135px;
}

/* Bet365 - First column of image file */
.logo-BT {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') no-repeat 0 -90px;
}

/* BetEasy - First column of image file */
.logo-BE {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') no-repeat 0 -180px;
}

/* BoomBet - First column of image file */
.logo-SB5 {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') no-repeat 0 -810px;
}

/* GT - First column of image file */
.logo-STT {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') no-repeat 0 -450px;
}

/* Custom logo for white label bookies */
.logo-GTX {
  width: 45px;
  height: 45px;
  background: url('~@/assets/custom_logo_round.png') no-repeat;
}

/* BestBookies - First column of image file */
/* .logo-BB2 {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') no-repeat 0 -45px;
} */

/* BBet - First column of image file */
.logo-BB4 {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') no-repeat 0 0;
}

/* BetDogs - First column of image file */
.logo-BD {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') no-repeat 0 -135px;
}

/* TAB NZ - First column of image file */
.logo-NZ {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') no-repeat 0 -1215px;
}

/* TAB Sportsbet (TAB QLD) - First column of image file */
.logo-TS_Q {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') no-repeat 0 -1125px;
}

/* TAB (TAB QLD) - First column of image file */
.logo-Q {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') no-repeat 0 -1125px;
}

/* TAB Sportsbet (TAB VIC) - First column of image file */
.logo-TS_V {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') no-repeat 0 -1035px;
}

/* TAB (TAB VIC) - First column of image file */
.logo-V {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') no-repeat 0 -1035px;
}

/* TAB NSW - First column of image file */
.logo-N {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') no-repeat 0 -1080px;
}

/* TAB NSW Sportsbet - First column of image file */
.logo-TS_N {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') no-repeat 0 -1080px;
}

/* Neds - First column of image file */
.logo-ND {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') no-repeat 0 -540px;
}

/* Baggy Bet - Second column of image file*/
.logo-BB {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') no-repeat -45px -1215px;
}

/* ChasBet Bet - Second column of image file*/
.logo-CHB {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') no-repeat -45px -1485px;
}

/* SportChamp Racing */
.logo-SCR {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') no-repeat -45px -1305px;
}

/* EskanderBet */
.logo-ESB {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') no-repeat -45px -1350px;
}

/* GetSetBet */
.logo-GSB {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') no-repeat 0 -1395px;
}

/* VIP Betting */
.logo-VPB {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') no-repeat -45px -1395px;
}

/* BetGOLD Betting */
.logo-BG {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') no-repeat -45px -1440px;
}

</style>
