<template>
  <div v-if="!signedIn" class="login d-flex align-center justify-center mt-n10" style="margin:0 auto;padding-top:10px;padding:30px;">
    <v-snackbar :value="!isEmpty(errorMsg.msg)" :top="true" :timeout="3200" :color="errorMsg.type">
      <div class="d-flex align-center">
        <v-icon class="mr-2">{{ errorMsg.icon }}</v-icon><span class="text-md-body-1">{{ errorMsg.msg }}</span>
      </div>
    </v-snackbar>
    <figure class="mb-7">
      <v-img width="170" :src="require('../assets/app-logo.svg')" alt="Dynamic Form Logo" />
    </figure>
    <div class="login__content">
      <div class="login__intro">
        <div class="login__logo">
          Hello
        </div>
        <header class="login__header">
          <h2>Welcome to {{ title }}</h2>
        </header>
        <p class="login__paragraph">
           Once you have logged in, should further information on features and functionality options of the new platform be required – please visit SUPPORT (top of the page) for step-by-step details.
        </p>
        <p class="login__paragraph">
          You can also visit and subscribe to our YouTube channel (tutorials in navbar) (more clips coming soon).
        </p>
        <p class="login__paragraph">
          Should your inquiry not be addressed via the above channels, please submit a support request
        </p>
        <p class="login__paragraph">
          For a better user experience, download the Desktop App. Download of this App is only required ONCE! <span>Please select your operating system below</span>
        </p>

        <div class="login__socials">

          <div class="login__apps d-flex justify-space-between justify-center mt-4">
            <a
              class="login__windows login__os align-center d-flex algin-center justify-center flex-column"
              href="https://new.dynamicodds.com/downloads/DynamicOdds_Setup_1.0.3.exe"
              download>
              <v-icon large size="28">mdi-microsoft-windows</v-icon>
              <span class="text-sm-body-2 mt-1">Windows 7/8/10</span>
            </a>
            <a
              class="login__mac login__os align-center d-flex algin-center justify-center flex-column"
              href="https://new.dynamicodds.com/downloads/DynamicOdds_Mac_Setup_1.0.3.dmg"
              download>
              <v-icon large size="28">mdi-apple</v-icon>
              <span class="text-sm-body-2 mt-1">MAC OS</span>
            </a>
            <a
              class="login__info login__os align-center d-flex algin-center justify-center flex-column"
              href="https://new.dynamicodds.com/downloads/Install_Instructions_MacOS.pdf"
              target="_blank"
              download>
              <v-icon size="30">mdi-card-text-outline</v-icon>
              <span class="text-sm-body-2 mt-1">MAC Install Instructions</span>
            </a>
          </div>
        </div>
      </div>

      <div class="login__form-container">
         <form class="login__form" v-if="formView === 'login'">
           <legend class="d-flex align-center flex-column">
            <p>{{ title }} Sign-in</p>
          </legend>

          <div class="form-group">
            <label for="username">Username</label>
            <input id="username" type="text" placeholder="Enter your username" v-model="username" required />
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <input id="password" type="password" placeholder="●●●●●●●●●" v-model="password" required />
          </div>

          <button @click="onSubmitUserCredentials">Sign In</button>
          <p class="login__form-text text-center mb-0 mt-3">Forgot your password? <span @click="onToggleBetweenViews({ view: 'reset-password-1' })">Lets change it!</span></p>
        </form>

        <form class="login__form" v-if="formView === 'reset-password-1'">
          <legend class="d-flex align-center flex-column">
            <p>Reset your password</p>
          </legend>
          <div class="form-group">
            <label for="username">Username</label>
            <input id="username" type="text" placeholder="Enter your username" v-model="username" required />
          </div>
          <button @click="onSubmitUsername">Submit</button>
          <p class="login__form-text text-center mb-0 mt-3">Remembered your password? <span @click="onToggleBetweenViews({ view: 'login' })">Lets login!</span></p>
        </form>

        <form class="login__form" v-if="formView === 'reset-password-2'">
          <legend class="d-flex align-center flex-column">
            <p>Reset your password</p>
          </legend>
          <div class="form-group">
            <label for="username">Username</label>
            <input id="username" type="text" placeholder="Enter your username" v-model="username" required />
          </div>
          <div class="form-group">
            <label for="code">code</label>
            <input id="code" type="text" placeholder="000000" v-model="code" required />
          </div>
          <div class="form-group">
            <label for="newPassword">New Password</label>
            <input id="newPassword" type="password" placeholder="●●●●●●●●●" v-model="newPassword" required />
          </div>
          <button @click="onSubmitConfirmResetPassword">Submit</button>
          <p class="login__form-text text-center mb-0 mt-3">Remembered your password? <span @click="onToggleBetweenViews({ view: 'login' })">Lets login!</span></p>
        </form>

        <form class="login__form" v-if="formView === 'signup'">
          <legend class="d-flex align-center flex-column">
            <p>Sign Up</p>
          </legend>
          <div class="form-group">
            <label for="username">Username</label>
            <input id="username" type="text" placeholder="Enter your username" v-model="username" required />
          </div>
          <div class="form-group">
            <label for="email">E-Mail</label>
            <input id="email" type="email" placeholder="Enter your email" v-model="email" required />
          </div>
          <div class="form-group">
            <label for="phoneNo">Phone Number</label>
            <!-- <input id="phoneNo" type="-text" placeholder="Enter your phone number" v-model="phoneNo" required /> -->
            <VuePhoneNumberInput default-country-code="AU" v-model="phoneNo" @update="updatePhoneNumber" />
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <input id="password" type="password" placeholder="●●●●●●●●●" v-model="password" required />
          </div>
          <button @click="onSubmitUserSignUpCredentials">Submit</button>
          <p class="login__form-text text-center mb-0 mt-3">Already have an account? <span @click="onToggleBetweenViews({ view: 'login' })">Lets login!</span></p>
        </form>

        <form class="login__form" v-if="formView === 'confirm-account'">
          <legend class="d-flex align-center flex-column">
            <p>Confirm your account</p>
          </legend>
          <div class="form-group">
            <label for="username">Username</label>
            <input id="username" type="text" placeholder="Enter your username" v-model="username" required />
          </div>
          <div class="form-group">
            <label for="code">Code</label>
            <input id="code" type="text" placeholder="Enter your code" v-model="code" required />
          </div>
          <button @click="onSubmitUserConfirmCredentials">Create Account!</button>
          <p class="login__form-text text-center mb-0 mt-3">Didn't recieve the code? <span @click="onResendCode">Resent code now!</span></p>
        </form>
      </div>
    </div>

    <div class="mt-7 login__btns">
      <!--<v-btn small class="mr-4" @click="updateFreetrial({ boolean: true })"><v-icon class="mr-1">mdi-grid</v-icon> Free Trial</v-btn>-->
      <!-- <v-btn small class="mr-4" href="https://betmakers.service-now.com/b2c-portal" target="_blank"><v-icon class="mr-1">mdi-lifebuoy</v-icon> Support</v-btn> -->
      <v-btn small class="mr-4" href="mailto:support@dynamicodds.com" target="_blank"><v-icon class="mr-1">mdi-lifebuoy</v-icon> Support</v-btn>
      <v-btn small class="mr-4" @click="onToggleBetweenViews({ view: 'signup' })" v-if="formView === 'login' || formView !== 'signup'"><v-icon class="mr-1">mdi-account-plus</v-icon> Sign Up</v-btn>
      <v-btn small class="mr-4" @click="onToggleBetweenViews({ view: 'login' })" v-if="formView === 'signup'"><v-icon class="mr-1">mdi-login</v-icon> Login</v-btn>
    </div>
  </div>
</template>
<script>
import { Auth } from '@aws-amplify/auth'
import { Hub } from '@aws-amplify/core'
import { AmplifyEventBus } from 'aws-amplify-vue'
import VuePhoneNumberInput from 'vue-phone-number-input'
import { mapActions, mapGetters } from 'vuex'
import Vue from 'vue'
import { isEmpty } from '../common/methods'

export default {
  name: 'AmpAuth',
  data () {
    return {
      signedIn: true,
      user: {},
      authToken: null,
      accessToken: null,
      username: '',
      password: '',
      code: '',
      newPassword: '',
      email: '',
      phoneNo: '',
      phoneNumberWithCode: '',
      errorMsg: {},
      formView: 'login'
    }
  },
  components: {
    VuePhoneNumberInput
  },
  props: {
    authMode: String
  },
  watch: {
    authMode: function (val) {
      this.signInMode(val)
    }
  },
  computed: {
    ...mapGetters(['hasFeature']),
    ...mapGetters('account', ['getMemberId']),
    checkBrowser () {
      if (typeof window !== 'undefined' && typeof window.process === 'object' && window.process.type === 'renderer') {
        return false
      }
      // Main process
      if (typeof process !== 'undefined' && typeof process.versions === 'object' && !!process.versions.electron) {
        return false
      }
      // Detect the user agent when the `nodeIntegration` option is set to true
      if (typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.indexOf('Electron') >= 0) {
        return false
      }
      return true
    },
    title () {
      return process.env.VUE_APP_TITLE || 'DynamicOdds'
    }
  },
  async mounted () {
    try {
      await Auth.currentSession()
    } catch {
      await Auth.signOut()
      this.signedIn = false
    }
    Hub.listen('auth', data => {
      switch (data.payload.event) {
        case 'signIn':
          Auth.currentAuthenticatedUser().then(user => {
            this.accessToken = user.getSignInUserSession().getAccessToken()
            localStorage.setItem('auth_token', this.accessToken.getJwtToken())
          })
          this.signedIn = true
          this.user.username = data.payload.data.username
          // this.signIn()
          this.$store.dispatch('fetchUserDetails')
          this.loginBetting()
          break
        case 'signOut':
          this.signedIn = false
          this.$store.dispatch('logoutBetting')
          this.$router.push('/login')
          break
      }
    })
    function patchSignIn () {
      let usernameComponent = Vue.component('amplify-username-field')
      let watches = usernameComponent.options.watch = usernameComponent.options.watch || {}
      watches.username = function () {
        this.usernameChanged()
      }
      watches.email = function () {
        this.emailChanged()
      }
    }
    patchSignIn()

    this.onInitialToggleLoginView()
  },
  methods: {
    ...mapActions(['signOut', 'signIn', 'addApolloClient', 'loginBetting']),
    ...mapActions('account', ['triggerFreeTrial']),
    isEmpty,
    signInMode: function (val) {
      if (val === 'signup') {
        AmplifyEventBus.$emit('authState', 'signUp')
      } else if (val === 'login') {
        AmplifyEventBus.$emit('authState', 'signIn')
      }
    },
    async onSubmitUserCredentials (event) {
      event.preventDefault()
      const { username, password } = this

      if (isEmpty(username)) {
        this.errorMsg = { msg: 'Username is required', icon: 'mdi-alert', type: 'red' }
        return setTimeout(() => { this.errorMsg = {} }, 3200)
      }

      if (isEmpty(password)) {
        this.errorMsg = { msg: 'Password is required', icon: 'mdi-alert', type: 'red' }
        return setTimeout(() => { this.errorMsg = {} }, 3200)
      }

      try {
        await Auth.signIn(username?.trim(), password?.trim())
      } catch (err) {
        this.errorMsg = { msg: err.message, icon: 'mdi-alert', type: 'red' }
        setTimeout(() => { this.errorMsg = {} }, 3200)
      }
    },
    async onSubmitUsername (event) {
      event.preventDefault()
      const { username } = this

      if (isEmpty(username)) {
        this.errorMsg = { msg: 'Username is required', icon: 'mdi-alert', type: 'red' }
        return setTimeout(() => { this.errorMsg = {} }, 3200)
      }

      try {
        await Auth.forgotPassword(username?.trim())
        this.errorMsg = { msg: 'Verification code had been sent to your email', type: 'success', icon: 'mdi-checkbox-marked-circle' }
        setTimeout(() => { this.errorMsg = {} }, 3200)
        this.formView = 'reset-password-2'
      } catch (err) {
        this.errorMsg = { msg: err.message, type: 'red', icon: 'mdi-alert' }
        setTimeout(() => { this.errorMsg = {} }, 3200)
      }
    },
    async onSubmitConfirmResetPassword (event) {
      event.preventDefault()
      const { username, code, newPassword } = this

      if (isEmpty(username)) {
        this.errorMsg = { msg: 'Username is required', type: 'red', icon: 'mdi-alert' }
        return setTimeout(() => { this.errorMsg = {} }, 3200)
      }

      if (isEmpty(code)) {
        this.errorMsg = { msg: 'Verification code is required', type: 'red', icon: 'mdi-alert' }
        return setTimeout(() => { this.errorMsg = {} }, 3200)
      }

      if (isEmpty(newPassword)) {
        this.errorMsg = { msg: 'Password is required', type: 'red', icon: 'mdi-alert' }
        return setTimeout(() => { this.errorMsg = {} }, 3200)
      }

      try {
        await Auth.forgotPasswordSubmit(username?.trim(), code?.trim(), newPassword?.trim())

        this.username = ''
        this.password = ''
        this.code = ''
        this.newPassword = ''
        this.formView = 'login'
        this.errorMsg = { msg: 'Password change was successful!', type: 'success', icon: 'mdi-checkbox-marked-circle' }
        return setTimeout(() => { this.errorMsg = {} }, 3200)
      } catch (err) {
        this.errorMsg = { msg: err.message, type: 'red', icon: 'mdi-alert' }
        setTimeout(() => { this.errorMsg = {} }, 3200)
      }
    },
    async onSubmitUserSignUpCredentials (event) {
      event.preventDefault()
      const { username, email, password, phoneNo, phoneNumberWithCode } = this

      if (isEmpty(username)) {
        this.errorMsg = { msg: 'Username is required', icon: 'mdi-alert', type: 'red' }
        return setTimeout(() => { this.errorMsg = {} }, 3200)
      }

      if (isEmpty(email)) {
        this.errorMsg = { msg: 'Email is required', icon: 'mdi-alert', type: 'red' }
        return setTimeout(() => { this.errorMsg = {} }, 3200)
      }

      if (isEmpty(phoneNo)) {
        this.errorMsg = { msg: 'Phone Number is required', icon: 'mdi-alert', type: 'red' }
        return setTimeout(() => { this.errorMsg = {} }, 3200)
      }

      if (isEmpty(password)) {
        this.errorMsg = { msg: 'Password is required', icon: 'mdi-alert', type: 'red' }
        return setTimeout(() => { this.errorMsg = {} }, 3200)
      }

      try {
        await Auth.signUp({
            username: username?.trim(),
            password: password?.trim(),
            attributes: {
              email: email?.trim(),
              phone_number: phoneNumberWithCode?.trim()
            }
        })
        this.onToggleBetweenViews({ view: 'confirm-account' })
        this.errorMsg = { msg: 'Please check your email for verification code', type: 'success', icon: 'mdi-checkbox-marked-circle' }
        return setTimeout(() => { this.errorMsg = {} }, 3200)
      } catch (err) {
        this.errorMsg = { msg: err.message, icon: 'mdi-alert', type: 'red' }
        return setTimeout(() => { this.errorMsg = {} }, 3200)
      }
    },
    async onSubmitUserConfirmCredentials (event) {
      event.preventDefault()
      const { username, code } = this

      if (isEmpty(username)) {
        this.errorMsg = { msg: 'Username is required', icon: 'mdi-alert', type: 'red' }
        return setTimeout(() => { this.errorMsg = {} }, 3200)
      }

      if (isEmpty(code)) {
        this.errorMsg = { msg: 'Verification code is required', icon: 'mdi-alert', type: 'red' }
        return setTimeout(() => { this.errorMsg = {} }, 3200)
      }

      try {
        await Auth.confirmSignUp(username?.trim(), code?.trim())
        this.onToggleBetweenViews({ view: 'login' })
        this.errorMsg = { msg: 'You account has been created!', type: 'success', icon: 'mdi-checkbox-marked-circle' }
        this.onClearInputFields()
        return setTimeout(() => { this.errorMsg = {} }, 3200)
      } catch (err) {
        this.errorMsg = { msg: err.message, icon: 'mdi-alert', type: 'red' }
        return setTimeout(() => { this.errorMsg = {} }, 3200)
      }
    },
    async onResendCode () {
      const { username } = this
      try {
        await Auth.resendSignUp(username?.trim())
        this.errorMsg = { msg: 'Verification code has been resent to your email', type: 'success', icon: 'mdi-checkbox-marked-circle' }
        return setTimeout(() => { this.errorMsg = {} }, 3200)
      } catch (err) {
        this.errorMsg = { msg: err.message, icon: 'mdi-alert', type: 'red' }
        return setTimeout(() => { this.errorMsg = {} }, 3200)
      }
    },
    onToggleBetweenViews ({ view }) {
      this.formView = view
    },
    onInitialToggleLoginView () {
      if (this.$route.path === '/signup') this.onToggleBetweenViews({ view: 'signup' })
    },
    onClearInputFields () {
      this.username = ''
      this.password = ''
      this.code = ''
      this.email = ''
      this.phoneNo = ''
      this.phoneNumberWithCode = ''
    },
    updatePhoneNumber (data) {
      if (data.isValid) {
        this.phoneNumberWithCode = data.formattedNumber
      }
    },
    updateFreetrial ({ boolean }) {
      this.triggerFreeTrial({ boolean })
      this.$router.push('/')
    }
  }
}
</script>
<style scoped>
.login {
  background-color: #f3f5f8;
  height: 100vh;
  color: #828282;
  flex-direction: column;
}
 .login__content {
  box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
  border-radius: 30px;
  display: flex;
  background-color: #fff;
  max-width: 1080px;
}
 .login__intro {
  width: 50%;
  padding-right: 10px;
  padding: 40px;
  display: flex;
  flex-direction: column;
}
 .login__logo {
  height: 20px;
  width: 20px;
}
 .login__header {
  color: #152939;
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 500;
}
 .login__header h2::after {
  content: '';
  background-color: #152939;
  display: block;
  height: 3px;
  border-radius: 100rem;
  width: 5%;
}
 .login__paragraph {
  font-size: 14.5px;
  margin-bottom: 10px !important;
}
 .login__apps a, .login__apps a:visited, .login__apps a:link {
  color: #828282;
  display: block;
}
 .login__os {
  background-color: transparent !important;
  cursor: pointer !important;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
  transition: transform ease 300ms !important;
  text-decoration: none;
}
 .login__os:hover {
  transform: translate(0, -5px) !important;
}
 .login__os span {
  display: block !important;
  font-size: 13px !important;
}
 .login__socials {
  margin-top: auto;
}
 .login__form-container {
  width: 50%;
  padding-left: 10px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  background: linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), url('~@/assets/login-screen.png') center center / cover;
  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
 .login__form {
  background-color: #fff;
  border-radius: 30px;
  width: 85%;
  flex-direction: column;
  padding: 35px;
}
 .login__form legend {
  color: #152939;
  margin-bottom: 30px;
  text-align: center;
}
 .login__form legend p {
  width: 100%;
  font-weight: 500;
  margin-top: 20px;
  font-size: 18.4px;
  margin: 0;
}
 .login__form .form-group {
  margin-bottom: 15px;
}
 .login__form .form-group label {
  display: block;
  font-size: 13.5px;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 5px;
  font-weight: 500;
  margin-bottom: 16px;
}
 .login__form .form-group input {
  display: block;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, .2);
  height: 42px !important;
  padding: 11.5px;
  font-size: 13.5px;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  font-style: italic;
  color: black;
  border-radius: 5px;
}
 .login__form .form-group input::placeholder {
  color: rgba(0, 0, 0, .25);
}
 .login__form .form-group input[type=date]:required:invalid::-webkit-datetime-edit {
  color: transparent;
}
 .login__form .form-group input[type=date]:focus::-webkit-datetime-edit {
  color: black !important;
}
 .login__form .form-group input[type="date"]:not(.has-value):before {
  color: rgba(0, 0, 0, .25);
  content: attr(placeholder);
}
 .login__form .form-group input[type="date"]:focus:before {
  content: '' !important;
}
 .login__form .form-group input:focus, .login__form .form-group input:hover {
  border-color: #4caf50;
  outline: 0;
}
 .login__form button {
  background: #4caf50;
  width: 100%;
  padding: 10px;
  text-transform: uppercase;
  color: white;
  font-weight: 500;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  height: 42px;
}
 .login__form-text {
  margin-top: 15px;
  margin-bottom: 0;
  font-size: 16px;
}
 .login__form-text span {
  color: #4caf50;
  cursor: pointer;
  text-decoration: underline;
}
 .login__btns button, .login__btns a {
  color: #152939 !important;
  height: 30px !important;
  font-size: 13px !important;
  background-color: white !important;
  box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px !important;
}
 .login__btns button i, .login__btns a i {
  font-size: 16px !important;
  color: red !important;
}
</style>
